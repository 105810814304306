import * as Yup from 'yup'
import {FormikContextType, useFormik} from 'formik'
import {LoginByTicketIdForm} from './forms/LoginByTicketIdForm'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useHistory} from 'react-router-dom'
import {LoginType} from './MyPassLogin'
import {Button} from '../../../components/inputs/Button'
import {GetTicketByBatchId} from '../redux/DigitalWaiverCRUD'
import {Helmet} from 'react-helmet'

export interface LoginByTicketIdProps {
  ticketId: string
}

const loginSchema = Yup.object().shape({
  ticketId: Yup.string().required('Ticket Id is required'),
})

const initialValues: LoginByTicketIdProps = {
  ticketId: '',
}

interface LoginByTIcketIdProps {
  setLoginType?: (data: LoginType) => void
  isNormalLogin?: boolean
}

export const LoginByTicketId = ({setLoginType, isNormalLogin}: LoginByTIcketIdProps) => {
  const history = useHistory()
  const {pushError} = useAlerts()

  const formik: FormikContextType<LoginByTicketIdProps> = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async ({ticketId}, {setStatus}) => {
      setStatus('')
      try {
        const {data} = await GetTicketByBatchId(ticketId)
        history.push(`/quick-waiver/${ticketId}`, data)
      } catch (err) {
        pushError(err)
      }
    },
  })

  return (
    <div className='d-flex flex-column justify-content-center rounded hv-100'>
      <Helmet>
        <title>Quick Waiver</title>
        <link rel='icon' href='/waiver.ico' />
      </Helmet>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form card'
      >
        <LoginByTicketIdForm formik={formik} />

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className='text-center d-flex gab-5 justify-content-center'>
          <button
            type='submit'
            className='btn text-white mb-5 btn-primary w-100'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!formik.isSubmitting && <span className='indicator-label'>Sign Waiver</span>}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* {setLoginType && (
          <Button
            variant='text'
            className='py-0 pt-3'
            onClick={() => setLoginType?.(isNormalLogin ? 'normal' : 'myPass')}
          >
            {`Go to ${isNormalLogin ? 'WEB•N•TECH' : 'MyPass'}  login`}
          </Button>
        )} */}
      </form>
    </div>
  )
}
